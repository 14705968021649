<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Faq : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col lg="20" md="12" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-2 mt-md-0"
            @click="modalAddFaq()"
          >
            <feather-icon icon="PlusIcon" class="mr-26" />
            <span>Tambah Faq</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- email -->
          <b-form-group label="Urutan" label-for="faq_urutan">
            <validation-provider #default="{ errors }" name="faq_urutan">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Search urutan faqs"
                  v-model="filter.faq_urutan"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- npa_id -->
          <b-form-group label="Title" label-for="faq_title">
            <validation-provider #default="{ errors }" name="faq_title">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Search title faqs"
                  v-model="filter.faq_title"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- npa_id -->
          <b-form-group label="Content" label-for="faq_content">
            <validation-provider #default="{ errors }" name="faq_content">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Search content faqs"
                  v-model="filter.faq_content"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" class="mb-25">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="refreshPage()"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="modalUpdateFaq(data.item)">
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteDataFaq(data.item)">
            <feather-icon icon="XSquareIcon" size="16" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <b-modal
      id="modal-add-faq"
      size="xl"
      title="Tambah Faq"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-form-group label="urutan" label-for="urutan ">
          <b-form-input id="urutan" v-model="faq_urutan" placeholder="urutan" />
        </b-form-group>
        <b-form-group label="judul" label-for="judul ">
          <b-form-input id="judul" v-model="faq_title" placeholder="judul" />
        </b-form-group>
        <b-form-group label="konten" label-for="konten ">
          <quill-editor v-model="faq_content" :options="snowOption" />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="saveDataFaq"
        >
          Tambah faq
        </b-button>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-update-faq"
      size="xl"
      atitle="Edit Faq"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-form-input id="id" v-model="updateFaq.id" placeholder="id" hidden />
        <b-form-group label="urutan" label-for="urutan ">
          <b-form-input
            id="urutan"
            v-model="updateFaq.faq_urutan"
            placeholder="urutan"
          />
        </b-form-group>
        <b-form-group label="judul" label-for="judul ">
          <b-form-input
            id="judul"
            v-model="updateFaq.faq_title"
            placeholder="judul"
          />
        </b-form-group>
        <b-form-group label="konten" label-for="konten ">
          <quill-editor v-model="updateFaq.faq_content" :options="snowOption" />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="updateDataFaq"
        >
          Update faq
        </b-button>
      </b-form>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        "faq_urutan",
        "faq_title",
        "faq_content",
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],

      search: null,
      updateFaq: {},
      base64Data: "",
      option: ["video", "image"],
      snowOption: {
        theme: "snow",
      },
      filter: {
        faq_urutan: "",
        faq_title: "",
        faq_content: "",
      },
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getFaq();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },

    modalAddFaq() {
      this.$bvModal.show("modal-add-faq");
    },

    modalUpdateFaq(item) {
      this.updateFaq = item;
      console.log(item);
      this.$bvModal.show("modal-update-faq");
    },

    async getFaq() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filter,
        };
        const { data } = await API.faq.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data faq");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async saveDataFaq() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        let datafaq = {
          faq_urutan: this.faq_urutan,
          faq_title: this.faq_title,
          faq_content: this.faq_content,
        };
        await API.faq.add(datafaq);
        this.refreshPage();
        this.handleMsgSuccess("Berhasil tambah faq");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "form yang anda isi salah");
      } finally {
        this.$bvModal.hide("modal-add-faq");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async updateDataFaq() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id = this.updateFaq.id;
        let body = {
          faq_urutan: this.updateFaq.faq_urutan,
          faq_title: this.updateFaq.faq_title,
          faq_content: this.updateFaq.faq_content,
        };
        await API.faq.update(id, body);
        this.refreshPage();
        this.handleMsgSuccess("Berhasil Update faq");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "gagal update faq");
      } finally {
        this.$bvModal.hide("modal-update-faq");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async deleteDataFaq(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        let idFaq = ids.id;

        const { data } = await API.faq.delete(idFaq);
        this.handleMsgSuccess("Berhasil Delete faq");
        this.refreshPage();
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "gagal delete faq");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getFaq();
  },
  watch: {
    currentPage() {
      this.getFaq();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
